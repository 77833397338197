.watch-page {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}
.watch-page h1 {
  font-size: 1.5em;
}
.watch-page-text {
  margin: 0 15px 100px;
}

iframe {
  width: 100%;
  min-height: 500px;
}

a {
  color: #c9a758;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.watch-page-chanel-link {
  display: flex;
  margin: 25px 5px 15px;
  align-items: center;
}
.watch-page-chanel-link img {
  height: 50px;
  width: 50px;
  margin-right: 5px;
}
