header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background-color: var(--header);
  overflow: scroll;
}
header h1, header a {
  text-decoration: none;
}
header h1 {
  color: var(--background);
  white-space: nowrap;
}
header a:hover {
  text-decoration-color: var(--background);
}
header img {
  width: 35px;
  height: 35px;
}
.header-color-line {
  height: 50px;
  width: 20px;
  margin-right: 10px;
}
.header-info {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
}
.header-day-date > span {
  margin-right: 5px;
}
.header-celebrations {
  display: flex;
  flex-shrink: 0;
  max-width: 850px;
}
.header-celebration {
  margin-left: 10px;
  border: 2px solid transparent;
  padding: 5px;
}
.home-page {
  margin: 0;
}
.home-page > img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.channel-previews-list {
  display: block;
}
.channel-previews-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 10px;
  justify-content: center;
}
.channel-preview {
  margin: 20px 0;
  text-align: center;
  max-width: 350px;
}
.channel-preview > a {
  color: black;
  text-decoration: none;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.channel-preview > a > img {
  height: 88px;
  width: 88px;
}
.channel-preview > a:hover {
  text-decoration: underline;
}

.layout-button-row {
  display: flex;
}
.layout-button {
  padding: 10px;
  border: 1px solid rgba(0,0,0, .6);
  border-radius: 5px;
  background-color: var(--background);
}
.layout-button-active {
  background-color: var(--secondary);
}

.videos {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 10px;
  row-gap: 10px;
}
.video {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: black;
  text-decoration: none;
}
.video-image-wrapper {
  position: relative;
  max-width: 350px;
}
.video-image-wrapper > div {
  color: white;
  background-color: black;
  position: absolute;
  bottom: 5px;
  right: 2px;
}
.video img {
  height: 150px;
  width: 100%;
  max-width: 350px;
  object-fit: cover;
}
.video:hover {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}
.video h5 {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 16px;
}
.video p {
  font-size: 14px;
}
.description {
  max-width: 600px;
  font-size: 16px;
  overflow-wrap: break-word;
}
.description > div > div {
  margin: 20px 0;
}
.tag {
  background-color: unset;
  color: black;
  border-radius: 7px;
  padding: 10px;
  margin-right: 2px;
  border: 1px solid black;
}
.tag:hover {
  background-color: rgba(0,0,0,0.1);
}
.tag:active {
  background-color: rgba(0,0,0,0.2);
}
.tag-active {
  font-weight: bold;
}

@media (max-width: 1000px) {
  .videos {
    grid-template-columns: auto auto auto;
  }
  .channel-previews-grid {
    grid-template-columns: auto auto;
  }
  header h1 {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .channel-preview {
    padding: 0;
  }
  .videos {
    grid-template-columns: auto auto;
  }
  .video {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .video > img {
    height: 150px;
    margin: 0;
    object-fit: cover;
  }
  .home-page > img {
    height: 300px;
  }
  header h1 {
    font-size: 18px;
  }
  header img {
    height: 25px;
    width: 25px;
  }
  .header-color-line {
    width: 10px;
    margin-right: 5px;
  }
}
@media (max-width: 400px) {
  .videos {
    grid-template-columns: auto;
  }
  .channel-previews-grid {
    grid-template-columns: auto;
  }
}

