html {
  --text: #0f0f0f;
  --primary: #f0f0f0;
  --background: #fffff6;
  --warning: #a11111;
  --secondary: #d1c29a;
  --info: #90c6ef;
  --header: #c9a758;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);
}
h1,h2,h3,h4,h5, p {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
