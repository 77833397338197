.chanel-page {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.chanel-page-youtube-link {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.chanel-page-youtube-link img {
  height: 20px;
  margin-left: 5px;
}
.chanel-page-title {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.chanel-page-description {
  font-size: 18px;
  max-width: 700px;
  margin: 20px 0;
}
